import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Plantation = ({ plot, handleSelectPlot, tg , fetchGameState, showErrorModal, lvl, balance, index}) => {
    const isPlanted = plot.status === 'planted';
    const [remainingTime, setRemainingTime] = useState(null);
    const [isReadyToHarvest, setIsReadyToHarvest] = useState(false);
    const { t } = useTranslation();

    let plotLevels = {
        1: 0,
        2: 2,
        3: 5,
        4: 15
    };

    let plotMoney = {
        1: 0,
        2: 100,
        3: 500,
        4: 1500
    };


    useEffect(() => {
        if (isPlanted) {
            const updateRemainingTime = () => {
                const timeLeft = plot.seconds_to_harvest * 1000; // перевести в миллисекунды
                if (timeLeft <= 0) {
                    setIsReadyToHarvest(true);
                } else {
                    const seconds = Math.floor((timeLeft / 1000) % 60);
                    const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
                    const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
                    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                    setRemainingTime(`${days}d ${hours}h ${minutes}m ${seconds}s`);
                }
            };

            const interval = setInterval(() => {
                plot.seconds_to_harvest -= 1;
                updateRemainingTime();
            }, 1000);

            updateRemainingTime(); // начальный вызов, чтобы сразу отобразить время

            return () => clearInterval(interval);
        }
    }, [isPlanted, plot.seconds_to_harvest]);

    const handleHarvest = async () => {
        try {
            const body = JSON.stringify({
                plotNumber: plot.plot_number
            });

            const response = await fetch(`${process.env.REACT_APP_API_URL}/harvest_tree.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tg.initData || '',
                    'Content-Length': body.length.toString()
                },
                body: body
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status === 'success') {
                fetchGameState()
            } else {
                showErrorModal(result.message)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClick = () => {


        if (isPlanted && isReadyToHarvest) {
            handleHarvest();
        } else if (!isPlanted) {
            if (plotLevels[plot.plot_number] > lvl) {
                showErrorModal('This plantation will be available from ' + plotLevels[plot.plot_number] + ' LVL and will cost ' + plotMoney[plot.plot_number]);
                return true;
            }

            handleSelectPlot(plot.plot_number);
        }
    };

    const getClassPlantation = (status) => {
        if(status == 'new' && plotLevels[index+1] <= lvl) {
            return '1'
        }
        switch (status) {
            case 'new':
                return '0';
            case 'empty':
                return '1';
            case 'planted':
                return '2';
            default:
                return '0';
        }
    };
    const classTree = "plantation-tree tree-" + plot.name;
    const classPlantation = "plantation plantation-" + getClassPlantation(plot.status);

    return (
        <div className={classPlantation} onClick={handleClick}>
            {isPlanted ? (

                <div className={classTree}>
                    <p className={"plantationTimer"}>
                        {isReadyToHarvest ? <>
                            {t('Ready to harvest')}
                        </> : <>{remainingTime}</> }</p>
                    {plot.seconds_to_harvest < 1 ? <button>{t('Harvest')}</button> : <></>}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Plantation;
