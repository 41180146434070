import React from 'react';
import Modal from '../Modal';

const ErrorModal = ({isOpen, onClose, message }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <h2>Error</h2>
        <p>{message}</p>
    </Modal>
);

export default ErrorModal;
