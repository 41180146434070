import React, { useState } from 'react';
import Modal from '../../Modal';

const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;

    const hh = h.toString().padStart(2, '0');
    const mm = m.toString().padStart(2, '0');
    const ss = s.toString().padStart(2, '0');

    return `${hh}:${mm}:${ss}`;
};

const PlantItemModal = ({ isOpen, onClose, name, growth_time, price, xp, coins, id, plantTree }) => {
    const [quantity, setQuantity] = useState(1);

    const increment = () => {
        setQuantity(quantity + 1);
    };

    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} inner={true} style={{ 'z-index': 9999 }}>

                <h4>{name}</h4>
                <div className={'row'}>
                    <div className={'text-left shopBuyLeft col-6'}>
                        Время роста: <b>{formatTime(growth_time)}</b><br />
                        Опыт: <b>+{xp}</b><br />
                        Доход: <b>{coins}</b>
                    </div>
                    <div className={`col-6 shopBuyIcon tree-${name}`}>

                    </div>
                </div>
                <div className="row" style={{marginTop: '30px', marginBottom: '20px'}}>
                    <div className="col-12">
                        <div className="quantity-selector">

                            <button style={{marginLeft: '30px', fontSize: 24}} onClick={() => plantTree(id)}>
                                Посадить
                            </button>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
        </Modal>
    );
};

export default PlantItemModal;
