import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Plantation from './components/Plantation';
import './App.css';
import './Trees.css';
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import ErrorModal from "./components/modals/ErrorModal";
import AnimatedIcon from "./components/AnimatedIcon";
import BackgroundMusic from "./components/BackgroundMusic";
import SoundPlayer from "./components/SoundPlayer";
import { useTranslation } from 'react-i18next';



const App = () => {
  const [isShopOpen, setShopOpen] = useState(false);
  const [isStoreOpen, setStoreOpen] = useState(false);
  const tg = window.Telegram.WebApp;
  const [plots, setPlots] = useState([]);
  const [balance, setBalance] = useState(0);
  const [xp, setXp] = useState(0);
  const [lvl, setLvl] = useState(0);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [soundIndex, setSoundIndex] = useState(null);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const playSound = (index) => {
    setSoundIndex(index);
  };

  const showCustomIcon = (id) => {
    const arr = [
      { "id": 1, "name": "Apple" },
      { "id": 2, "name": "Pear" },
      { "id": 3, "name": "Cherry" },
      { "id": 4, "name": "Plum" },
      { "id": 5, "name": "Apricot" },
      { "id": 6, "name": "Peach" },
      { "id": 7, "name": "Lemon" },
      { "id": 8, "name": "Lime" },
      { "id": 9, "name": "Orange" },
      { "id": 10, "name": "Mango" },
      { "id": 11, "name": "Grapefruit" },
      { "id": 12, "name": "Pomegranate" }
    ]

    const iconContainer = document.createElement('div');
    document.body.appendChild(iconContainer);
    const root = createRoot(iconContainer);
    root.render(<AnimatedIcon className={arr[id].name} />);

    // Remove the container after animation
    setTimeout(() => {
      root.unmount();
      document.body.removeChild(iconContainer);
    }, 3000); // Match the duration of the animation
  };


  const showErrorModal = (message) => {
    setStoreOpen(false)
    setShopOpen(false)
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage('');
  };

  const logMessage = async (message) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/log_message.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });
    } catch (error) {
      console.error('Error logging message:', error);
    }
  };
  const fetchGameState = async () => {
    // setPlots([]);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_state.php`, {
        headers: {
          'Authorization': tg.initData || ''
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setBalance(data.balance);
      setXp(data.xp);
      setLvl(data.lvl);
      setPlots(data.plots);
    } catch (error) {
      logMessage(`Error fetching game state: ${error.message}`);
    }
  };

  useEffect(() => {

    if(tg && tg.initDataUnsafe && tg.initDataUnsafe.user && tg.initDataUnsafe.user.language_code) {
      const newLang = tg.initDataUnsafe.user.language_code || 'en'; // Получаем язык из Telegram.WebApp
      changeLanguage(newLang)
    } else {
      changeLanguage('ru')
    }
    tg.ready();
    fetchGameState();

    tg.onEvent('themeChanged', (theme) => {
      document.body.style.backgroundColor = theme.backgroundColor;
      document.body.style.color = theme.textColor;
      console.log('Theme changed:', theme);
    });

    return () => {
      tg.offEvent('themeChanged');
    };
  }, [tg]);

  const handleSelectPlot = (plotId) => {
    setSelectedPlot(plotId)
    setStoreOpen(true);
  }



  return (
      <div className="App">
        <Header balance={balance} xp={xp} lvl={lvl}/>
        <BackgroundMusic/>
        <div className="plantations">
          {plots.map((plot, index) => (
              <Plantation
                  key={index}
                  plot={plot}
                  handleSelectPlot={handleSelectPlot}
                  fetchGameState={fetchGameState}
                  tg={tg}
                  showErrorModal={showErrorModal}
                  lvl={lvl}
                  index={index}
              />
          ))}
        </div>
        <Footer
            showCustomIcon={showCustomIcon}
            showErrorModal={showErrorModal}
            selectedPlot={selectedPlot}
            setSelectedPlot={setSelectedPlot}
            tg={tg}
            fetchGameState={fetchGameState}
            isShopOpen={isShopOpen}
            setShopOpen={setShopOpen}
            isStoreOpen={isStoreOpen}
            setStoreOpen={setStoreOpen}
            lvl={lvl}
        />

        <ErrorModal
            isOpen={isErrorModalOpen}
            onClose={closeErrorModal}
            message={errorMessage}
        />
        <SoundPlayer
            soundIndex={soundIndex}
        />

      </div>
  );
};

export default App;
