import React, { useState } from 'react';
import Modal from '../../Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import money from "../../../images/money.png";

const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;

    const hh = h.toString().padStart(2, '0');
    const mm = m.toString().padStart(2, '0');
    const ss = s.toString().padStart(2, '0');

    return `${hh}:${mm}:${ss}`;
};

const BuyItemModal = ({ isOpen, onClose, name, growth_time, price, xp, coins, id, handleBuy, myCurrentLvl, lvl }) => {
    const [quantity, setQuantity] = useState(1);

    const increment = () => {
        setQuantity(quantity + 1);
    };

    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} inner={true} style={{ 'z-index': 9999 }}>
            {myCurrentLvl>=lvl ? (<>
                <h4>{name}</h4>
                <div className={'row'}>
                    <div className={'text-left shopBuyLeft col-6'}>
                        Время роста: <b>{formatTime(growth_time)}</b><br />
                        Опыт: <b>+{xp}</b><br />
                        Доход: <b>{coins}</b>
                    </div>
                    <div className={`col-6 shopBuyIcon tree-${name}`}>

                    </div>
                </div>
                <div className="row" style={{marginTop: '30px', marginBottom: '20px'}}>
                    <div className="col-12">
                        <div className="quantity-selector">
                            <button className="decrement-btn" onClick={decrement}>
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            <input
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                                className="quantity-input"
                                readOnly={true}
                            />
                            <button className="increment-btn" onClick={increment}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <button style={{marginLeft: '30px', fontSize: 24}} onClick={() => handleBuy(id, quantity)}>
                                <b>{quantity * price}</b>
                                <img src={money} style={{width: '24px', height : '24px', margin : '0 10px 0 20px'}} />
                            </button>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </>) : (
                <>
                    <h4>You need lvl {lvl}</h4>
                </>
            )}

        </Modal>
    );
};

export default BuyItemModal;
