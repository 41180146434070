import React, {useState} from 'react';
import RatingModal from "./modals/RatingModal";
import BuyItemModal from "./modals/inner/BuyItemModal";

const Tree = ({ id, name, price, growth_time, coins, xp, onSelect, buyTree, myCurrentLvl, lvl }) => {
    const [isBuyItem, setIsBuyItem] = useState(false);
    const classTree = "tree tree-" + name;

    const handleBuy = (id, quantity = 1) => {
        buyTree(id, quantity)
        setIsBuyItem(false)
    }
    return (
        <>
            <div className={classTree} onClick={() => setIsBuyItem(true)} title={name}>
                <span>{price}</span>
            </div>

            <BuyItemModal
                lvl={lvl}
                myCurrentLvl={myCurrentLvl}
                growth_time={growth_time}
                price={price}
                xp={xp}
                name={name}
                coins={coins}
                id={id}
                isOpen={isBuyItem}
                handleBuy={handleBuy}
                onClose={() => setIsBuyItem(false)}
            />
        </>
    );
};

export default Tree;
