import React from 'react';

const Modal = ({ isOpen, onClose, inner = false, children }) => {
    if (!isOpen) return null;
    let classNm = "modal-overlay"

    if(inner) {
        classNm += ' modal-overlay-inner'
    }

    return (

        <div className={classNm}>
            <div className="modal">
                <button className="close-button" onClick={onClose}>×</button>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
