import React from 'react';
import money from '../images/money.png';

const Header = ({ balance, xp, lvl}) => {
    return (
        <div className="header">
            <div className="balance">{balance} <img width={16} src={money}/> </div>
            <div className="xp">{xp} XP</div>
            <div className="level">{lvl} lvl</div>
        </div>
    );
};

export default Header;
