import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import PlantItemModal from "./inner/PlantItemModal";
import SellItemModal from "./inner/SellItemModal";

const StoreModal = ({ isOpen, onClose, tg, setStoreOpen, setShopOpen, selectedPlot, fetchGameState, showErrorModal, showCustomIcon, treeOptions}) => {
    const [inventory, setInventory] = useState([]);
    const [error, setError] = useState(null);
    const [quantityReady, setQuantityReady] = useState(1);
    const [selectedItemId, setSelecteItemId] = useState(null);
    const [selectedItemSellId, setSelecteItemSellId] = useState(null);

    const [isPlantItem, setIsPlantItem] = useState(false);
    const [isSellItem, setIsSellItem] = useState(false);

    const handlePlantTree = (itemId) => {
        setSelecteItemId(itemId)
        setIsPlantItem(true)
    }

    const handlePlantSell = (itemId, quantityReady = 0) => {
        setSelecteItemSellId(itemId)
        setIsSellItem(true)
        setQuantityReady(quantityReady)
    }

    const fetchInventory = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_store.php`, {
                method: 'POST',
                headers: {
                    'Authorization': tg.initData || ''
                },
                body: JSON.stringify({
                    // если нужно отправить какие-либо данные в теле запроса
                }),
            });

            const result = await response.json();

            if (response.ok) {
                setInventory(result.inventory);
            } else {
                showErrorModal(result.message || 'Что-то пошло не так')
            }
        } catch (error) {
            showErrorModal(error.message || 'Что-то пошло не так')
        }
    };

    const plantTree = async (itemId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/plant_tree.php`, {
                method: 'POST',
                headers: {
                    'Authorization': tg.initData || '',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ plot_number: selectedPlot, item_id: itemId }),
            });

            const result = await response.json();
            if (result.status === 'success') {
                fetchInventory()
                fetchGameState()
                showCustomIcon(itemId-1)
                setIsPlantItem(false)
                setStoreOpen(false)
            } else {
                showErrorModal(result.message || 'Что-то пошло не так')
            }
        } catch (error) {
            showErrorModal(error.message || 'Что-то пошло не так')
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchInventory();
        }
    }, [isOpen]);

    const handleSell = async (itemId, quantity = 1) => {
        try {
            const body = JSON.stringify({
                itemId,
                quantity: quantity
            });

            const response = await fetch(`${process.env.REACT_APP_API_URL}/sell_from_inventory.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tg.initData || '',
                    'Content-Length': body.length.toString()
                },
                body: body
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status === 'success') {
                fetchInventory()
                fetchGameState()
                showCustomIcon(itemId-1)
                setIsSellItem(false)
            } else {
                showErrorModal(result.message || 'Что-то пошло не так')
            }
        } catch (error) {
            showErrorModal(error.message || 'Что-то пошло не так')
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Store</h2>
            {error ? (
                <p>Ошибка: {error}</p>
            ) : (
                <div>
                    <h3>Семена на посадку</h3>
                    <ul className="store">
                        {inventory.filter(item => item.quantity > 0).map(item => {
                            const className = "tree tree-" + item.tree_name;
                            return (
                                <li className={className} key={item.item_id} onClick={() => handlePlantTree(item.item_id)}>
                                    <span>{item.quantity}</span>
                                </li>
                            );
                        })}
                        <li className="buy_new_one"><span   onClick={() => {setShopOpen(true); setStoreOpen(false); }}> +</span></li>
                    </ul>
                    <h3>Урожай на продажу</h3>
                    <ul className="store">
                        {inventory.filter(item => item.quantity_ready > 0).map(item => {
                            const className = "tree tree-" + item.tree_name;
                            return (
                                <li className={className} key={item.item_id + '-ready'} onClick={() => handlePlantSell(item.item_id, item.quantity_ready)}>
                                    <span>{item.quantity_ready}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            {selectedItemId && (<PlantItemModal
                growth_time={treeOptions[selectedItemId-1].growth_time}
                price={treeOptions[selectedItemId-1].price}
                xp={treeOptions[selectedItemId-1].xp}
                name={treeOptions[selectedItemId-1].name}
                coins={treeOptions[selectedItemId-1].coins}
                id={selectedItemId}
                plantTree={plantTree}
                isOpen={isPlantItem}
                onClose={() => setIsPlantItem(false)}
            />)}

            {selectedItemSellId && (<SellItemModal
                growth_time={treeOptions[selectedItemSellId-1].growth_time}
                price={treeOptions[selectedItemSellId-1].price}
                xp={treeOptions[selectedItemSellId-1].xp}
                name={treeOptions[selectedItemSellId-1].name}
                coins={treeOptions[selectedItemSellId-1].coins}
                total={quantityReady}
                id={selectedItemSellId}
                handleSell={handleSell}
                isOpen={isSellItem}
                onClose={() => setIsSellItem(false)}
            />)}

        </Modal>
    );


};

export default StoreModal;
