import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

// Функция для копирования текста в буфер обмена
const copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
        document.execCommand('copy');
        alert('Ссылка скопирована!');
    } catch (err) {
        console.error('Ошибка копирования: ', err);
    }
    document.body.removeChild(textarea);
};

const FriendsModal = ({ isOpen, onClose, tg }) => {
    const [list, setList] = useState([]);
    const [userId, setUserId] = useState(null);
    const [referralLink, setReferralLink] = useState('');

    useEffect(() => {
        let tmpUserId = ''
        if (tg && tg.initDataUnsafe && tg.initDataUnsafe.user) {
            const user = tg.initDataUnsafe.user;
            if (user && user.id) {
                tmpUserId = user.id;
                setUserId(user.id);
            }
        }
        setReferralLink(`https://t.me/HappyTree_bot?start=${tmpUserId}`);
    }, [tg]);

    useEffect(() => {
        const fetchList = async () => {
            console.log('fetchList')
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/get_referrals.php`, {
                    method: 'POST',
                    headers: {
                        'Authorization': tg.initData || '',
                        'Content-Type': 'application/json'
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    setList(result.list);
                } else {
                    throw new Error(result.message || 'Что-то пошло не так');
                }
            } catch (error) {
                console.error('Error fetching rating data:', error);
            }
        };

        if (isOpen) {
            fetchList();
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Друзья</h2>
                <div>
                    <p>Ваша реферальная ссылка:</p>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">🔗</span>
                        </div>
                        <input type="text" value={referralLink} readOnly />
                    </div>
                    <div>
                        <button className="default" onClick={() => copyToClipboard(referralLink)}>Копировать ссылку</button>
                    </div>
                    <h4>Ваши друзья</h4>

                    <table style={{"width": "100%", "marginTop": "20px"}}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Login</th>
                            <th>Balance</th>
                            <th>XP</th>
                            <th>Lvl</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((user, index) => (
                            <tr key={user.username}>
                                <td>{index + 1}</td>
                                <td>{user.username}</td>
                                <td>{user.balance}</td>
                                <td>{user.xp}</td>
                                <td>{user.lvl}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>



                </div>
        </Modal>
    );
};

export default FriendsModal;
