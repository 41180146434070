import React from 'react';
import Modal from '../Modal';

const BonusModal = ({ isOpen, onClose }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <h2>Бонусы</h2>
        <p>Содержимое бонусов...</p>
    </Modal>
);

export default BonusModal;
