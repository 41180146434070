import React, { useEffect } from 'react';

const SoundPlayer = ({ soundIndex }) => {
    const sounds = [
        '/public/intro.mp3',
        '/public/sound2.mp3',
        '/public/sound3.mp3',
    ];

    useEffect(() => {
        if (soundIndex !== null && soundIndex >= 0 && soundIndex < sounds.length) {
            const audio = new Audio(sounds[soundIndex]);
            audio.play().catch((e) => {
                console.log('Play was prevented:', e);
            });
        }
    }, [soundIndex, sounds]);

    return null;
};

export default SoundPlayer;
