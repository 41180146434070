// src/components/RatingModal.js
import React, { useEffect, useState } from 'react';
import Modal from '../Modal';

const RatingModal = ({ isOpen, onClose, tg }) => {
    const [ratings, setRatings] = useState([]);
    const [userRank, setUserRank] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRating = async () => {
            try {
                console.log('start rty');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/get_rating.php`, {
                    method: 'POST',
                    headers: {
                        'Authorization': tg.initData || '',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        // если нужно отправить какие-либо данные в теле запроса
                    }),
                });

                const result = await response.json();
                console.log('result', result);

                if (response.ok) {
                    setRatings(result.top100);
                    setUserRank(result.userRank);
                } else {
                    throw new Error(result.message || 'Что-то пошло не так');
                }
            } catch (error) {
                setError(error.message);
                console.error('Error fetching rating data:', error);
            }
        };

        if (isOpen) {
            fetchRating();
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Рейтинг</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {userRank !== null ? (
                <p>Ваше место в рейтинге: {userRank}</p>
            ) : (
                <p>Загрузка...</p>
            )}
            <table style={{"width": "100%"}}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Login</th>
                        <th>Balance</th>
                        <th>XP</th>
                    </tr>
                </thead>
                <tbody>
                {ratings.map((user, index) => (
                    <tr key={user.username}>
                        <td>{index + 1}</td>
                        <td>{user.username}</td>
                        <td>{user.balance}</td>
                        <td>{user.xp}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </Modal>
    );
};

export default RatingModal;
