import React, { useState, useEffect } from 'react';

const AnimatedIcon = ({ className }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (className) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsVisible(false); // Remove the icon after the animation completes
            }, 3000); // Match the duration of the animation

            return () => clearTimeout(timer);
        }
    }, [className]);

    return (
        <div>
            {isVisible && <div className={`icon-animation tree-${className}`} />}
        </div>
    );
};

export default AnimatedIcon;
