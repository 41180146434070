import React from 'react';
import Modal from '../Modal';
import Tree from '../Tree';

const ShopModal = ({ isOpen, onClose, setSelectedTree, tg, fetchGameState, showCustomIcon, showErrorModal, lvl, treeOptions }) => {
        const buyTree = async (itemId, quantity = 1) => {
                console.log('buy_tree')
                try {
                        const response = await fetch(`${process.env.REACT_APP_API_URL}/buy_tree.php`, {
                                method: 'POST',
                                headers: {
                                        'Authorization': tg.initData || '',
                                        'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ item_id: itemId, quantity }),
                        });

                        const result = await response.json();
                        console.log(result);
                        if (result.status === 'success') {
                                fetchGameState()
                                showCustomIcon(itemId-1)
                        } else {
                                showErrorModal(result.message)
                        }
                } catch (error) {
                        showErrorModal(error)
                }
        };

        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                    <h2>Магазин</h2>
                    <div className="tree-options">
                            {treeOptions.map((tree) => (
                                <Tree buyTree={buyTree} key={tree.id} {...tree} myCurrentLvl={lvl} onSelect={setSelectedTree} />
                            ))}
                    </div>
            </Modal>
        );
};

export default ShopModal;
