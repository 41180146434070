import React, {useState} from 'react';
import shop from '../images/shop.png';
import store from '../images/store.png';
import bonus from '../images/bonus.png';
import friends from '../images/friends.png';
import rating from '../images/rating.png';
import { useTranslation } from 'react-i18next';

import ShopModal from "../components/modals/ShopModal";
import StoreModal from "../components/modals/StoreModal";
import BonusModal from "../components/modals/BonusModal";
import FriendsModal from "../components/modals/FriendsModal";
import RatingModal from "../components/modals/RatingModal";



const Footer = ({ setSelectedPlot, tg, fetchGameState, isShopOpen, setShopOpen, isStoreOpen, setStoreOpen, selectedPlot, showErrorModal, showCustomIcon, lvl}) => {
    const [isBonusOpen, setBonusOpen] = useState(false);
    const [isFriendsOpen, setFriendsOpen] = useState(false);
    const [isRatingOpen, setRatingOpen] = useState(false);
    const { t } = useTranslation();

    const treeOptions = [
        { id: 1, name: 'Apple', count_ref: 0, price: 10, lvl: 0, growth_time: 7200, coins: 15, xp: 10, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:35:48' },
        { id: 2, name: 'Pear', count_ref: 3, price: 20, lvl: 0, growth_time: 10800, coins: 50, xp: 20, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:37:03' },
        { id: 3, name: 'Cherry', count_ref: 5, price: 30, lvl: 0, growth_time: 14400, coins: 80, xp: 35, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:37:40' },
        { id: 4, name: 'Plum', count_ref: 8, price: 80, lvl: 0, growth_time: 18000, coins: 120, xp: 50, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:38:26' },
        { id: 5, name: 'Apricot', count_ref: 10, price: 14, lvl: 5, growth_time: 21600, coins: 150, xp: 75, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:03' },
        { id: 6, name: 'Peach', count_ref: 14, price: 20, lvl: 10, growth_time: 25200, coins: 180, xp: 100, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:06' },
        { id: 7, name: 'Lemon', count_ref: 20, price: 27, lvl: 15, growth_time: 28800, coins: 200, xp: 120, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:08' },
        { id: 8, name: 'Lime', count_ref: 25, price: 38, lvl: 20, growth_time: 32400, coins: 220, xp: 155, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:10' },
        { id: 9, name: 'Orange', count_ref: 30, price: 54, lvl: 25, growth_time: 36000, coins: 250, xp: 180, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:12' },
        { id: 10, name: 'Mango', count_ref: 35, price: 75, lvl: 28, growth_time: 39600, coins: 300, xp: 225, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:15' },
        { id: 11, name: 'Grapefruit', count_ref: 40, price: 105, lvl: 31, growth_time: 43200, coins: 330, xp: 300, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:17' },
        { id: 12, name: 'Pomegranate', count_ref: 50, price: 148, lvl: 34, growth_time: 46800, coins: 420, xp: 350, created_at: '2024-07-30 00:39:40', updated_at: '2024-08-04 22:48:19' },
    ];


    return (
        <div className="footer">
            <ul>
                <li onClick={() => setShopOpen(true)}><img src={shop} alt={t('Shop')} />{t('Shop')}</li>
                <li onClick={() => setStoreOpen(true)}><img src={store} alt={t('Store')}/>{t('Store')}</li>
                <li onClick={() => setBonusOpen(true)}><img src={bonus} alt={t('Bonus')} />{t('Bonus')}</li>
                <li onClick={() => setFriendsOpen(true)}><img src={friends} alt={t('Friends')} />{t('Friends')}</li>
                <li onClick={() => setRatingOpen(true)}><img src={rating} alt={t('Rating')} />{t('Rating')}</li>
            </ul>

            <ShopModal treeOptions={treeOptions} showCustomIcon={showCustomIcon} showErrorModal={showErrorModal} isOpen={isShopOpen} onClose={() => setShopOpen(false)} tg={tg} fetchGameState={fetchGameState} lvl={lvl}/>
            <StoreModal treeOptions={treeOptions} showCustomIcon={showCustomIcon} showErrorModal={showErrorModal} isOpen={isStoreOpen} onClose={() => {setStoreOpen(false); setSelectedPlot(null)}}  tg={tg} setStoreOpen={setStoreOpen} setShopOpen={setShopOpen} selectedPlot={selectedPlot} fetchGameState={fetchGameState}/>
            <BonusModal isOpen={isBonusOpen} onClose={() => setBonusOpen(false)}   tg={tg}/>
            <FriendsModal isOpen={isFriendsOpen} onClose={() => setFriendsOpen(false)} tg={tg}/>
            <RatingModal isOpen={isRatingOpen} onClose={() => setRatingOpen(false)} tg={tg}/>
        </div>
    );
};

export default Footer;
