import React, { useState } from 'react';
import Modal from '../../Modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import money from "../../../images/money.png";

const PlantItemModal = ({ isOpen, onClose, name, growth_time, price, xp, coins, id, handleSell, total }) => {
    const [quantity, setQuantity] = useState(1);

    const increment = () => {
        if(quantity < total) {
            setQuantity(quantity + 1);
        }
    };

    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} inner={true} style={{ 'z-index': 9999 }}>

            <h4>{name}</h4>
            <div className={'row'}>
                <div className={'text-left shopBuyLeft col-6'}>
                    Наличие: <b>{total}</b><br />
                    Опыт: <b>+{xp * quantity}</b><br />
                    Доход: <b>{coins * quantity}</b>
                </div>
                <div className={`col-6 shopBuyIcon tree-${name}`}>

                </div>
            </div>
            <div className="row" style={{marginTop: '30px', marginBottom: '20px'}}>
                <div className="col-12">
                    <div className="quantity-selector">
                        <button className="decrement-btn" onClick={decrement}>
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <input
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                            className="quantity-input"
                            readOnly={true}
                        />
                        <button className="increment-btn" onClick={increment}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <button style={{marginLeft: '30px', fontSize: 24}} onClick={() => handleSell(id, quantity)}>
                            <b>Продать</b>
                            
                        </button>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PlantItemModal;
