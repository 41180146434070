import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const BackgroundMusic = () => {
    const [isPlaying, setIsPlaying] = useState(true);
    const [audio] = useState(new Audio('/public/intro.mp3'));

    useEffect(() => {
        audio.loop = true;

        const playAudio = () => {
            audio.play().catch((e) => {
                console.log('Play was prevented:', e);
                document.addEventListener('click', handleUserInteraction);
            });
        };

        const handleUserInteraction = () => {
            audio.play().catch((e) => {
                console.log('Play was prevented even after user interaction:', e);
            });
            document.removeEventListener('click', handleUserInteraction);
        };

        if (isPlaying) {
            playAudio();
        } else {
            audio.pause();
        }

        return () => {
            audio.pause();
            document.removeEventListener('click', handleUserInteraction);
        };
    }, [isPlaying, audio]);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <div style={{ position: 'fixed', right: '10%'}}>
            <button onClick={togglePlay} style={{ fontSize: '24px', border: 'none', background: 'none', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
            </button>
        </div>
    );
};

export default BackgroundMusic;
